/* Admonitions */

.admonition {
	@include icon('fas');
	margin: 3em 1em 2em 1em;
	padding: 2em 4em 2em 4em;
	border-radius: 2em;
	page-break-inside: avoid;

	&::before {
		float: left;
		height: 2em;
		width: 2em;
		margin: -2em 0 0 -2em;
		border-radius: 100%;
		text-align: center;
		font-size: 2em;
		line-height: 2em;
	}

	&.info {
		background-color: #daecff;

		&::before {
			background-color: #316096;
			color: #ffaa00;
			content: '\f129';
		}
	}

	&.warning {
		background-color: #fff7da;

		&::before {
			background-color: #ffc653;
			color: #870000;
			content: '\f071';
		}
	}

	.title {
		font-weight: bold;
		font-size: 1.75em;
		text-decoration: underline;
	}

}
