@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&family=Roboto+Slab:wght@400;700&family=Source+Code+Pro&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css');

/*
	Editorial by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

// Breakpoints.

	@include breakpoints((
		xlarge:   ( 1281px,  1680px ),
		large:    ( 981px,   1280px ),
		medium:   ( 737px,   980px  ),
		small:    ( 481px,   736px  ),
		xsmall:   ( 361px,   480px  ),
		xxsmall:  ( null,    360px  ),
		xlarge-to-max:    '(min-width: 1681px)',
		small-to-xlarge:  '(min-width: 481px) and (max-width: 1680px)'
	));

// Base.

	@import 'base/reset';
	@import 'base/page';
	@import 'base/typography';

// Component.

	@import 'components/row';
	@import 'components/section';
	@import 'components/form';
	@import 'components/box';
	@import 'components/icon';
	@import 'components/image';
	@import 'components/list';
	@import 'components/actions';
	@import 'components/icons';
	@import 'components/contact';
	@import 'components/pagination';
	@import 'components/table';
	@import 'components/button';
	@import 'components/mini-posts';
	@import 'components/features';
	@import 'components/posts';
	@import 'components/admonitions';

// Layout.

	@import 'layout/wrapper';
	@import 'layout/main';
	@import 'layout/sidebar';
	@import 'layout/header';
	@import 'layout/banner';
	@import 'layout/footer';
	@import 'layout/menu';