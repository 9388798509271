///
/// Editorial by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	#wrapper {
		@include vendor('display', 'flex');
		@include vendor('flex-direction', 'row-reverse');
		min-height: 100vh;
	}