///
/// Editorial by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Mini Posts */

	.mini-posts {
		article {
			border-top: solid 1px _palette(border);
			margin-top: _size(element-margin);
			padding-top: _size(element-margin);

			.image {
				display: block;
				margin: 0 0 (_size(element-margin) * 0.75) 0;

				img {
					display: block;
					width: 100%;
				}
			}

			&:first-child {
				border-top: 0;
				margin-top: 0;
				padding-top: 0;
			}
		}
	}