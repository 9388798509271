///
/// Editorial by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;

		li {
			display: inline-block;
			padding: 0 1em 0 0;

			&:last-child {
				padding-right: 0;
			}

			.icon {
				color: inherit;

				&:before {
					font-size: 1.25em;
				}
			}
		}
	}

	/* "External" icon after external links */
	a[href^="http"] {
		@include icon('fas', "\f35d", after);

		&::after {
			font-size: 50%;
			vertical-align: super;
			padding-left: 0.25em;
		}
	}

	a[href^="tel"]::before {
		content: "📞 ";
	}
