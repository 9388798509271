///
/// Editorial by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Footer */

	#footer {
		border-top: 1px solid _palette(border);
		padding-top: 1em;

		#copyright {
			font-size: 0.9em;
			font-weight: _font(weight-light);

			a {
				color: inherit;
			}
		}
	}